import gql from "graphql-tag";

//作成
export const CREATE_OPERATOR_WORK_LOG = gql`
  mutation createOperatorWorkLog($input: OperatorWorkLogInput!) {
    createOperatorWorkLog(input: $input) {
      operatorWorkLog {
        id
        operator {
          id
          username
        }
        equipmentsoperatorworklogsSet {
          edges {
            node {
              id
              equipment {
                id
                name
              }
            }
          }
        }
        operatorWork {
          id
          name
          equipmentsoperatorworksSet {
            edges {
              node {
                id
                equipment {
                  id
                  name
                }
              }
            }
          }
        }
        field {
          id
          name
        }
        fieldName
        workTimeStart
        workTime
        workPlace
        date
        remark
        operatorworklogdetailsSet {
          edges {
            node {
              id
              row {
                id
                name
              }
              section {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

// 更新
export const UPDATE_OPERATOR_WORK_LOG = gql`
  mutation updateOperatorWorkLog($id: ID!, $input: OperatorWorkLogInput!) {
    updateOperatorWorkLog(id: $id, input: $input) {
      operatorWorkLog {
        id
        operator {
          id
          username
        }
        equipmentsoperatorworklogsSet {
          edges {
            node {
              id
              equipment {
                id
                name
              }
            }
          }
        }
        operatorWork {
          id
          name
          equipmentsoperatorworksSet {
            edges {
              node {
                id
                equipment {
                  id
                  name
                }
              }
            }
          }
        }
        field {
          id
          name
        }
        fieldName
        workTimeStart
        workTime
        workPlace
        date
        remark
        operatorworklogdetailsSet {
          edges {
            node {
              id
              row {
                id
                name
              }
              section {
                id
                name
              }
              rowName
              sectionName
            }
          }
        }
      }
    }
  }
`;

//削除
export const DELETE_OPERATOR_WORK_LOG = gql`
  mutation deleteOperatorWorkLog($id: ID!) {
    deleteOperatorWorkLog(id: $id) {
      operatorWorkLog {
        id
        operator {
          id
          username
        }
        equipmentsoperatorworklogsSet {
          edges {
            node {
              id
              equipment {
                id
                name
              }
            }
          }
        }
        operatorWork {
          id
          name
          equipmentsoperatorworksSet {
            edges {
              node {
                id
                equipment {
                  id
                  name
                }
              }
            }
          }
        }
        field {
          id
          name
        }
        fieldName
        workTimeStart
        workTime
        workPlace
        date
        remark
        operatorworklogdetailsSet {
          edges {
            node {
              id
              row {
                id
                name
              }
              section {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
