<template>
  <div>
    <b-modal ref="dialog" @ok="deleteOperatorWorkLog">削除してもよろしいですか</b-modal>
  </div>
</template>

<script>
import { DELETE_OPERATOR_WORK_LOG } from "../../components/operator-work-log-mutation";

export default {
  props: ["id"],
  methods: {
    showDeleteDialog: function() {
      this.$refs["dialog"].show();
    },
    // --------------------------------
    // 削除
    // --------------------------------
    deleteOperatorWorkLog: function() {
      this.$apollo
        .mutate({
          mutation: DELETE_OPERATOR_WORK_LOG,
          variables: {
            id: this.id
          }
        })
        .then(data => {
          this.$emit("refetch", -1);
        })
        .catch(error => {
          console.error(error);
          alert(error);
        });
    }
  }
};
</script>
