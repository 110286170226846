<template>
  <div v-if="$apollo.loading"></div>
  <div v-else>
    <operator-work-form :propOperatorWork="operatorWork" @getOperatorWork="setOperatorWork" />
    <div v-if="operatorWork">
      <b-row>
        <b-col lg="12">
          <b-form-group>
            <template slot="label">
              <!-- toggleAllが呼ばれる -->
              <div v-if="equipmentsCheckBox.length != 0">
                <b-form-checkbox
                  v-model="allSelected"
                  :indeterminate="indeterminate"
                  @change="toggleAll"
                >{{ allSelected ? "選択解除" : "すべて選択" }}</b-form-checkbox>
              </div>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <b-row>
              <div v-for="item in equipmentsCheckBox" :key="item.id" :value="item">
                <b-col>
                  <b-form-checkbox :value="item" v-model="selectedEquipments">
                    {{
                    item.name
                    }}
                  </b-form-checkbox>
                </b-col>
              </div>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { allSettled, allResolved } from "q";
import OperatorWorkForm from "./form/OperatorWorkForm";

const DEFAULT_INDEX = 0;

export default {
  components: {
    OperatorWorkForm
  },
  data() {
    return {
      operatorWork: null,
      equipmentsCheckBox: [],
      allSelected: false,
      indeterminate: false,
      selectedEquipments: []
    };
  },
  props: {
    propOperatorWorkLog: {
      default: null
    }
  },
  watch: {
    operatorWork: function() {
      this.equipmentsCheckBox = [];
      this.selectedEquipments = [];
      this.allSelected = false;
      if (this.operatorWork) {
        const equipments = this.operatorWork.equipmentsoperatorworksSet.edges;

        for (let i = 0; i < equipments.length; i++) {
          this.equipmentsCheckBox.push({
            id: equipments[i].node.equipment.id,
            name: equipments[i].node.equipment.name
          });
        }

        if (this.propOperatorWorkLog) {
          const workLogsEquipments = this.propOperatorWorkLog
            .equipmentsoperatorworklogsSet.edges;
          for (let i = 0; i < workLogsEquipments.length; i++) {
            this.selectedEquipments.push({
              id: workLogsEquipments[i].node.equipment.id,
              name: workLogsEquipments[i].node.equipment.name
            });
          }
        }
      }
    },
    selectedEquipments: function() {
      this.pushValue();
    }
  },
  created() {
    if (this.propOperatorWorkLog) {
      this.operatorWork = this.propOperatorWorkLog.operatorWork;

      // チェックボックス項目を取得
      const operatorWorkEquipments = this.operatorWork
        .equipmentsoperatorworksSet.edges;

      for (let i = 0; i < operatorWorkEquipments.length; i++) {
        this.equipmentsCheckBox.push({
          id: operatorWorkEquipments[i].node.equipment.id,
          name: operatorWorkEquipments[i].node.equipment.name
        });
      }

      const equipments = this.propOperatorWorkLog.equipmentsoperatorworklogsSet
        .edges;

      for (let i = 0; i < equipments.length; i++) {
        this.selectedEquipments.push({
          id: equipments[i].node.equipment.id,
          name: equipments[i].node.equipment.name
        });
      }
    }
  },
  methods: {
    // 作業内容が選択された時、機材CheckBoxを更新
    setOperatorWork(operatorWork) {
      this.operatorWork = operatorWork;
      this.selectedEquipments = [];
      this.pushValue();
    },

    // 全選択
    toggleAll(checked) {
      this.selectedEquipments = [];
      if (checked) {
        this.selectedEquipments = this.equipmentsCheckBox;
      }
    },

    pushValue() {
      let equipmentIds = [];
      for (let i = 0; i < this.selectedEquipments.length; i++) {
        equipmentIds.push({
          equipmentId: this.selectedEquipments[i].id
        });
      }
      let setValue = {
        id: this.operatorWork.id,
        equipmentIds: equipmentIds
      };

      this.$emit("getOperatorWork", setValue);
    }
  }
};
</script>
