import gql from "graphql-tag";

export const CREATE_OPERATOR_DAILY_REPORT = gql`
  mutation createOperatorDailyReport($input: OperatorDailyReportInput!) {
    createOperatorDailyReport(input: $input) {
      operatorDailyReport {
        id
        operator {
          id
          username
        }
        date
        remark
      }
    }
  }
`;

export const UPDATE_OPERATOR_DAILY_REPORT = gql`
  mutation updateOperatorDailyReport($id: ID!, $input: OperatorDailyReportInput!) {
    updateOperatorDailyReport(id: $id, input: $input) {
      operatorDailyReport {
        id
        operator {
          id
          username
        }
        date
        remark
      }
    }
  }
`;

export const DELETE_OPERATOR_DAILY_REPORT = gql`
  mutation deleteOperatorDailyReport($id: ID) {
    deleteOperatorDailyReport(id: $id) {
      operatorDailyReport {
        id
        operator {
          id
          username
        }
        date
        remark
      }
    }
  }
`;
