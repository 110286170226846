<template>
  <div v-if="$apollo.loading"></div>
  <div v-else>
    <b-form-group>
      <label for="workTimeStart">作業開始時間</label>
      <b-form-select
        id="workTimeStart"
        autocomplete="off"
        :options="times()"
        v-model="workTimeStart"
        @change="updateValue"
      ></b-form-select>
    </b-form-group>
  </div>
</template>


<script>
import { DATE_OPERATOR_WORK_LOGS_QUERY } from "../../../components/operator-work-log-query";
import moment from "moment";
export default {
  data() {
    return {
      operatorWorkLogs: [],
      workTimeStart: null
    };
  },
  props: {
    propWorkTimeStart: {
      default: null
    },
    propCreateCheck: {
      default: null
    }
  },
  computed: {
    date: function() {
      let date = new Date();
      return moment(date).format("YYYY-MM-DD");
    }
  },
  watch: {
    propWorkTimeStart: function() {
      this.workTimeStart = this.workTimeStart = moment(
        this.propWorkTimeStart,
        "hh:mm"
      ).format("H:mm");
      this.$emit("getWorkTimeStart", this.workTimeStart);
    },
    propCreateCheck: function() {
      if (this.propCreateCheck) {
        this.$apollo.queries.operatorWorkLogs.refetch();
      }
    }
  },
  created() {
    if (this.propWorkTimeStart) {
      this.workTimeStart = moment(this.propWorkTimeStart, "hh:mm").format(
        "H:mm"
      );
      this.$emit("getWorkTimeStart", this.workTimeStart);
    }
  },
  apollo: {
    operatorWorkLogs: {
      query: DATE_OPERATOR_WORK_LOGS_QUERY,
      variables() {
        return {
          date: this.date
        };
      },
      result({ data, loading }) {
        if (!this.propWorkTimeStart) {
          this.initWorkTimeStart();
          this.$emit("getWorkTimeStart", this.workTimeStart);
        }
      },
      error(error) {
        console.error("WorkTimeStart", error);
      }
    }
  },
  methods: {
    times: function() {
      let times = [];
      for (let i = 4; i < 28; i++) {
        times.push((i % 24) + ":00");
        times.push((i % 24) + ":30");
      }
      return times;
    },
    initWorkTimeStart: function() {
      if (this.operatorWorkLogs.edges.length == 0) {
        this.workTimeStart = "4:00";
        return;
      }
      let index = 0;
      for (let i = 1; i < this.operatorWorkLogs.edges.length; i++) {
        if (
          this.operatorWorkLogs.edges[index].node.workTimeStart <
          this.operatorWorkLogs.edges[i].node.workTimeStart
        ) {
          index = i;
        }
      }
      let workTime = this.operatorWorkLogs.edges[index].node.workTimeStart;
      workTime = moment(workTime, "hh:mm").format("H:mm");
      let hour = Number(workTime.slice(0, -3)) * 60;
      let min = Number(workTime.slice(-2));
      let time = this.operatorWorkLogs.edges[index].node.workTime + hour + min;

      if (time / 60 <= 24) {
        this.workTimeStart = moment(
          time / 60 + ":" + (time % 60),
          "hh:mm"
        ).format("H:mm");
      } else {
        this.workTimeStart = "4:00";
      }
    },
    updateValue: function() {
      this.$emit("getWorkTimeStart", this.workTimeStart);
    }
  }
};
</script>
