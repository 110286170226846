<template>
  <div v-if="$apollo.loading"></div>
  <div v-else>
    <b-form-group>
      <b-form-select :options="times()" v-model="time" @change="updateValue"></b-form-select>
    </b-form-group>
  </div>
</template>


<script>
import moment from "moment";
export default {
  data() {
    return {
      time: this.propTime
    };
  },
  props: {
    propTime: {
      default: "0:00"
    }
  },
  watch: {
    propTime: function() {
      this.time = moment(this.propTime, "hh:mm").format("H:mm");
      this.$emit("getTime", this.time);
    }
  },
  created() {
    this.time = moment(this.time, "hh:mm").format("H:mm");
    this.$emit("getTime", this.time);
  },
  methods: {
    times: function() {
      let times = [];
      for (let i = 0; i < 24; i++) {
        times.push((i % 24) + ":00");
        times.push((i % 24) + ":30");
      }
      return times;
    },
    updateValue: function() {
      this.$emit("getTime", this.time);
    }
  }
};
</script>
