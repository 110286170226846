<template>
  <div>
    <b-form-group label="作業場所" label-for="work_place">
      <b-form-radio-group v-model="workPlace" :options="options" size="sm" @input="selected"></b-form-radio-group>
      <div v-if="workPlace.text　== this.workPlaceList.FIELD">
        <section-row-check-box :propField="propField" :propRows="propRows" @getField="setField" />
      </div>
      <div v-else>
        <b-form-input
          type="text"
          v-model="workPlaceName"
          :disabled="workPlace.disabled"
          @change="inputWorkPlace"
        ></b-form-input>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import SectionRowCheckBox from "./SectionRowCheckBox";
import { workPlaceList } from "../../components/config";

export default {
  components: {
    SectionRowCheckBox
  },
  data() {
    return {
      workPlaceName: this.propWorkPlace,
      workPlace: { text: this.propWorkPlace, disabled: true },
      workPlaceList: workPlaceList,
      options: [
        { text: "畑", value: { text: workPlaceList.FIELD, disabled: true } },
        {
          text: "オフィス",
          value: { text: workPlaceList.OFFICE, disabled: true }
        },
        { text: "その他(入力)", value: { text: null, disabled: false } }
      ]
    };
  },
  props: {
    propField: {
      default: null
    },
    propRows: {
      type: Array,
      default: () => []
    },
    propWorkPlace: {
      default: "畑"
    }
  },
  created() {
    if (
      !(
        this.propWorkPlace == workPlaceList.FIELD ||
        this.propWorkPlace == workPlaceList.OFFICE
      )
    ) {
      this.workPlace = { text: null, disabled: false };
    }
  },
  watch: {
    propWorkPlace: function() {
      if (
        !(
          this.propWorkPlace == workPlaceList.FIELD ||
          this.propWorkPlace == workPlaceList.OFFICE
        )
      ) {
        this.workPlace = { text: null, disabled: false };
      }
    }
  },
  methods: {
    inputWorkPlace: function() {
      this.$emit("getWorkPlace", null, null, this.workPlaceName);
    },
    selected: function() {
      if (this.workPlace.text != this.workPlaceList.FIELD) {
        this.workPlaceName = this.workPlace.text;
        this.$emit("getWorkPlace", null, null, this.workPlaceName);
      }
    },
    setField: function(field, rows) {
      this.$emit("getWorkPlace", field, rows, this.workPlace.text);
    }
  }
};
</script>
