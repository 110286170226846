import gql from "graphql-tag";

export const OPERATOR_WORK_LOGS_QUERY = gql`
  query(
    $first: Int
    $last: Int
    $operatorId: ID
    $date_Gte: Date
    $date_Lt: Date
  ) {
    operatorWorkLogs(
      first: $first
      last: $last
      orderBy: "-date,-work_time_start"
      operatorId: $operatorId
      date_Gte: $date_Gte
      date_Lt: $date_Lt
    ) {
      totalCount
      edges {
        node {
          id
          equipmentsoperatorworklogsSet {
            edges {
              node {
                id
                equipment {
                  id
                  name
                }
              }
            }
          }
          operator {
            id
            username
          }
          operatorWork {
            id
            name
            equipmentsoperatorworksSet {
              edges {
                node {
                  id
                  equipment {
                    id
                    name
                  }
                }
              }
            }
          }
          field {
            id
            name
          }
          fieldName
          workTimeStart
          workTime
          workPlace
          date
          remark
          operatorworklogdetailsSet {
            edges {
              node {
                id
                row {
                  id
                  name
                }
                section {
                  id
                  name
                  rowsSet{
                    edges{
                      node{
                        id
                        name
                      }
                    }
                  }
                }
                rowName
                sectionName
              }
            }
          }
        }
      }
    }
  }
`;

export const DATE_OPERATOR_WORK_LOGS_QUERY = gql`
  query($date: Date) {
    operatorWorkLogs(date: $date) {
      edges {
        node {
          id
          date
          workTimeStart
          workTime
        }
      }
    }
  }
`;
