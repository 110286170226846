<template>
  <div v-if="$apollo.loading"></div>
  <div v-else>
    <b-form-group>
      <label for="operatorWork">作業内容</label>
      <b-form-select :plain="true" v-model="operatorWork" @change="updateValue">
        <option
          v-for="item in operatorWorks.edges"
          :key="item.node.id"
          :value="item.node"
        >{{item.node.name}}</option>
      </b-form-select>
    </b-form-group>
  </div>
</template>


<script>
import { OPERATOR_WORKS_QUERY } from "../../../components/operator-work-query";
export default {
  props: {
    propOperatorWork: {
      default: null
    }
  },
  data() {
    return {
      operatorWorks: [],
      operatorWork: this.propOperatorWork
    };
  },
  watch: {
    propOperatorWork: function(val) {
      this.operatorWork = val;
      this.$emit("getOperatorWork", this.operatorWork);
    }
  },
  created() {
    if (this.propOperatorWork) {
      this.$emit("getOperatorWork", this.operatorWork);
    }
  },
  apollo: {
    operatorWorks: {
      query: OPERATOR_WORKS_QUERY,
      result({ data, loading }) {
        if (!this.propOperatorWork) {
          this.operatorWork = this.operatorWorks.edges[0].node;
          this.$emit("getOperatorWork", this.operatorWork);
        }
      },
      error(error) {
        console.error("OperatorWork", error);
      }
    }
  },
  methods: {
    updateValue: function(e) {
      this.$emit("getOperatorWork", this.operatorWork);
    }
  }
};
</script>
