import gql from "graphql-tag";

export const OPERATOR_DAILY_REPORTS_QUERY = gql`
query ($date: Date, $operatorId: ID!){
  operatorDailyReports(date:$date, operatorId:$operatorId){
    edges{
      node{
        id
        operator{
          id
          username
        }
        date
        remark
      }
    }
  }
}
`
