<template>
  <div>
    <label>日付</label>
    <datepicker
      :language="ja"
      v-model="date"
      format="yyyy-MM-dd"
      name="datepicker"
      id="date"
      input-class="input-class"
    ></datepicker>
    <br />
  </div>
</template>


<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { ja } from "vuejs-datepicker/dist/locale";
export default {
  components: {
    Datepicker
  },
  data() {
    return {
      date: null,
      ja: ja
    };
  },
  props: {
    propDate: {
      default: null
    }
  },
  watch: {
    propDate: function() {
      this.date = this.propDate;
    },
    date: function() {
      this.$emit("getDate", moment(this.date).format("YYYY-MM-DD"));
    }
  },
  created() {
    if (this.propDate) {
      this.date = this.propDate;
      return;
    }
    let today = new Date();
    this.date =
      String(today.getFullYear()) +
      "-" +
      String(today.getMonth() + 1) +
      "-" +
      String(today.getDate());
    this.date = moment(this.date).format("YYYY-MM-DD");
  }
};
</script>

