<template>
  <div v-if="$apollo.loading"></div>
  <div v-else>
    <b-form-group>
      <label for="operator">作業員</label>
      <b-form-select :plain="true" v-model="operator.id">
        <option :value="operator.id" disabled>{{operator.username}}</option>
      </b-form-select>
    </b-form-group>
  </div>
</template>


<script>
import { CURRENT_OPERATOR_QUERY } from "../../../components/operator-query";
export default {
  data() {
    return {
      operator: []
    };
  },
  apollo: {
    operator: {
      query: CURRENT_OPERATOR_QUERY,
      variables: {
        id: localStorage.getItem("graphcool-user-id") //localstrageから取得
      },
      result({ data, loading }) {
        this.$emit("getOperator", this.operator);
      },
      error(error) {
        console.error("Operator", error);
      }
    }
  }
};
</script>
