<template>
  <div>
    <operator-workLog-create-modal
      :propOperatorWorkLog="operatorWorkLog"
      @refetch="operatorWorkLogRefetch"
      ref="operatorWorkLogCreateModal"
    />
    <operator-work-log-edit-modal
      :propOperatorWorkLog="editOperatorWorkLog"
      @refetch="operatorWorkLogRefetch"
      ref="operatorWorkLogEditModal"
    />
    <b-row>
      <b-col>
        <b-card>
          <full-calendar
            locale="local"
            timeZone="Asia/Tokyo"
            selectable="true"
            height="auto"
            ref="fullCalendar"
            :defaultView="defaultView"
            :allDaySlot="false"
            :plugins="plugins"
            :header="{left:'prev,next', center: 'title', right:'dayGridMonth,timeGridWeek,timeGridDay'}"
            :buttonText="{today:'今日', month:'月', week:'週', day:'日', list:'スケジュール'}"
            :events="events"
            @eventClick="eventClick"
            @dateClick="dateClick"
            @select="select"
            @datesRender="datesRender"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// -- FullCalendar --
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/locales/ja";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import moment from "moment";

// -- Query --
import gql from "graphql-tag";
import { JOB_QUERY } from "../../components/job-query";
import { ROBOT_QUERY } from "../../components/job-query";
import { OPERATOR_WORK_LOGS_QUERY } from "../../components/operator-work-log-query";

// -- Components --
import OperatorWorkLogCreateModal from "./OperatorWorkLogCreateModal";
import OperatorWorkLogUpdateModal from "./OperatorWorkLogUpdateModal";
import OperatorWorkLogEditModal from "./OperatorWorkLogEditModal";

export default {
  components: {
    FullCalendar,
    OperatorWorkLogCreateModal,
    OperatorWorkLogEditModal
  },
  data() {
    return {
      plugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        momentTimezonePlugin
      ],
      events: [],
      operatorWorkLogs: {},
      operatorWorkLog: {},
      editOperatorWorkLog: {}
    };
  },
  props: {
    propDate: {
      default: null
    }
  },
  computed: {
    defaultView: function() {
      let ua = navigator.userAgent;
      let device;
      if (
        ua.indexOf("iPhone") > 0 ||
        ua.indexOf("iPod") > 0 ||
        (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
      ) {
        device = "sp";
      } else if (ua.indexOf("iPad") > 0 || ua.indexOf("Android") > 0) {
        device = "tab";
      } else {
        device = "other";
      }
      if (device == "sp" || device == "tab") {
        return "timeGridDay";
      } else {
        return "timeGridWeek";
      }
    }
  },
  watch: {
    operatorWorkLogs: {
      handler: function(newVal, oldVal) {
        this.initEvent();
      },
      deep: true
    },
    propDate: function() {
      let calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.gotoDate(this.propDate);
    }
  },
  methods: {
    // --------------------------------
    // カレンダーイベントクリック時
    // --------------------------------
    eventClick: function(info) {
      let index = this.operatorWorkLogs.edges.findIndex(
        nodes => nodes.node.id === info.event.id
      );
      let date = Date.parse(
        this.operatorWorkLogs.edges[index].node.date +
          "T" +
          this.operatorWorkLogs.edges[index].node.workTimeStart
      );

      let workTimeEnd = new Date(date);
      workTimeEnd.setMinutes(
        workTimeEnd.getMinutes() +
          this.operatorWorkLogs.edges[index].node.workTime
      );
      this.editOperatorWorkLog = this.operatorWorkLogs.edges[index].node;
      this.editOperatorWorkLog.workTimeEnd = workTimeEnd;
      this.$refs.operatorWorkLogEditModal.showEditDialog();
    },
    // --------------------------------
    // 日付選択
    // --------------------------------
    dateClick: function(info) {
      let date = moment(info.date).format("YYYY-MM-DD");
      let workTimeStart = moment(info.date);
      let workTimeEnd = moment(info.date).add(30, "m");
      this.operatorWorkLog = {
        date: date,
        workTimeStart: workTimeStart,
        workTimeEnd: workTimeEnd,
        workTime: 30
      };
      this.$refs.operatorWorkLogCreateModal.showDialog();
    },
    select: function(info) {
      let date = moment(info.start).format("YYYY-MM-DD");
      let workTimeStart = moment(info.start);
      let workTimeEnd = moment(info.end);
      let workTime = workTimeEnd.diff(workTimeStart, "minutes");

      this.operatorWorkLog = {
        date: date,
        workTimeStart: workTimeStart,
        workTimeEnd: workTimeEnd,
        workTime: workTime
      };

      this.$refs.operatorWorkLogCreateModal.showDialog();
    },
    datesRender: function(info) {
      let dateGte = moment(info.view.activeStart).format("YYYY-MM-DD");
      let dateLt = moment(info.view.activeEnd).format("YYYY-MM-DD");
      this.getOperatorWorkLog(dateGte, dateLt);
    },
    // --------------------------------
    // calendar eventの初期化
    // --------------------------------
    initEvent: function() {
      let color;
      let date;
      let startDateTime;
      let endDateTime;
      this.events = [];
      this.operatorWorkLogs.edges.forEach(element => {
        date = Date.parse(element.node.date + "T" + element.node.workTimeStart);

        startDateTime = new Date(date);
        endDateTime = new Date(date);
        endDateTime.setMinutes(
          endDateTime.getMinutes() + element.node.workTime
        );
        this.events.push({
          id: element.node.id,
          title: element.node.operatorWork.name,
          start: startDateTime,
          end: endDateTime
        });
      });
    },
    operatorWorkLogRefetch: function() {
      this.$apollo.queries.operatorWorkLogs.refetch();
    },
    getOperatorWorkLog: function(dateGte, dateLt) {
      this.$apollo.addSmartQuery("operatorWorkLogs", {
        query: OPERATOR_WORK_LOGS_QUERY,
        variables: {
          operatorId: localStorage.getItem("graphcool-user-id"),
          date_Gte: dateGte,
          date_Lt: dateLt
        }
      });
    }
  }
};
</script>

<style>
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
</style>
