<template>
  <div>
    <!-- ダイアログ -->
    <b-modal ref="dialog" @hidden="close" hide-footer title="変更">
      <b-row>
        <b-col>
          <date-form :prop-date="this.propOperatorWorkLog.date" @getDate="setDate" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <operator-form @getOperator="setOperatorId" />
        </b-col>
        <b-col>
          <operator-work-form
            :prop-operator-work="this.propOperatorWorkLog.operatorWork"
            @getOperatorWork="setOperatorWorkId"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <work-place-select-form
            :prop-field="this.propOperatorWorkLog.field"
            :prop-rows="this.rows"
            :prop-work-place="this.propOperatorWorkLog.workPlace"
            @getWorkPlace="setWorkPlace"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <work-time-start-form
            :prop-work-time-start="this.propOperatorWorkLog.workTimeStart"
            @getWorkTimeStart="setWorkTimeStart"
          />
        </b-col>
        <b-col>
          <work-time-form
            :prop-work-time="this.propOperatorWorkLog.workTime"
            @getWorkTime="setWorkTime"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="備考" label-for="remark">
            <b-form-input id="remark" type="text" v-model="operatorWorkLog.remark"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button type="submit" size="sm" variant="primary" v-on:click="updateOperatorWorkLog">
        <i class="fa fa-dot-circle-o"></i> 更新
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import OperatorForm from "./form/OperatorForm";
import OperatorWorkForm from "./form/OperatorWorkForm";
import WorkTimeStartForm from "./form/WorkTimeStartForm";
import WorkTimeForm from "./form/WorkTimeForm";
import WorkPlaceSelectForm from "./WorkPlaceSelectForm";
import DateForm from "./form/DateForm";
import { UPDATE_OPERATOR_WORK_LOG } from "../../components/operator-work-log-mutation";
import gql from "graphql-tag";

export default {
  components: {
    OperatorForm,
    OperatorWorkForm,
    WorkTimeStartForm,
    WorkTimeForm,
    DateForm,
    WorkPlaceSelectForm
  },
  data() {
    return {
      operatorWorkLog: {
        operatorId: null,
        operatorWorkId: null,
        fieldId: null,
        fieldName: null,
        workTimeStart: null,
        workTime: null,
        workPlace: null,
        remark: "",
        date: "",
        operatorworklogdetails: []
      },
      rows: []
    };
  },
  props: {
    propOperatorWorkLog: {
      type: Object,
      require: false,
      default: () => ({})
    }
  },
  computed: {},
  watch: {
    propOperatorWorkLog: function() {
      this.init();
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (Object.keys(this.propOperatorWorkLog).length) {
        this.operatorWorkLog.remark = this.propOperatorWorkLog.remark;
        this.rows = [];
        let length = this.propOperatorWorkLog.operatorworklogdetailsSet.edges
          .length;
        for (let i = 0; i < length; i++) {
          this.rows.push(
            this.propOperatorWorkLog.operatorworklogdetailsSet.edges[i].node.row
          );
        }
      }
    },
    setOperatorId(operator) {
      this.operatorWorkLog.operatorId = operator.id;
    },
    setOperatorWorkId(operatorWork) {
      this.operatorWorkLog.operatorWorkId = operatorWork.id;
    },
    setWorkTimeStart(workTimeStart) {
      this.operatorWorkLog.workTimeStart = workTimeStart;
    },
    setWorkTime(workTime) {
      this.operatorWorkLog.workTime = workTime;
    },
    setWorkPlace(field, rows, workPlace) {
      if (!field) {
        this.operatorWorkLog.fieldId = field;
        this.operatorWorkLog.fieldName = field;
        this.operatorWorkLog.operatorworklogdetails = [];
        this.operatorWorkLog.workPlace = workPlace;
        return;
      }
      this.operatorWorkLog.fieldId = field.id;
      this.operatorWorkLog.fieldName = field.name;
      this.operatorWorkLog.operatorworklogdetails = [];
      this.operatorWorkLog.workPlace = workPlace;
      for (let i = 0; i < rows.length; i++) {
        this.operatorWorkLog.operatorworklogdetails.push({
          sectionId: rows[i].section.id,
          rowId: rows[i].id,
          sectionName: rows[i].section.name,
          rowName: rows[i].name
        });
      }
    },
    setDate(date) {
      this.operatorWorkLog.date = date;
    },
    close: function() {
      this.init();
    },
    // --------------------------------
    // 変更ボタン
    // --------------------------------
    showUpdateDialog: function() {
      this.$refs["dialog"].show();
    },
    // --------------------------------
    // 更新
    // --------------------------------
    updateOperatorWorkLog: function() {
      this.$apollo
        .mutate({
          mutation: UPDATE_OPERATOR_WORK_LOG,
          variables: {
            id: this.propOperatorWorkLog.id,
            input: this.operatorWorkLog
          }
        })
        .then(data => {
          this.$refs["dialog"].hide();
        })
        .catch(error => {
          alert("更新エラー");
        });
    }
  }
};
</script>
