<template>
  <div v-if="$apollo.loading"></div>
  <div v-else>
    <field-form :propField="propField" @getField="setField"></field-form>
    <div v-if="field">
      <b-row>
        <b-col lg="12">
          <b-form-group>
            <template slot="label">
              <b-form-checkbox
                v-model="allSelected"
                :indeterminate="indeterminate"
                @change="toggleAll"
              >{{ allSelected ? '選択解除' : 'すべて選択' }}</b-form-checkbox>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <b-row>
              <div v-for="(item, index) in this.sections" :key="item.node.id" :value="item.node">
                <b-col>
                  <b-form-checkbox
                    v-model="selectSections"
                    :value="item"
                    @change="toggleSection(index)"
                  >{{ field.name+item.node.name}}</b-form-checkbox>
                  <div v-for="rowItem in item.node.rowsSet.edges" :key="rowItem.node.id">
                    <b-form-checkbox
                      :value="{section:item.node,row:rowItem.node}"
                      v-model="selectRows"
                    >{{rowItem.node.name}}</b-form-checkbox>
                  </div>
                </b-col>
              </div>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>


<script>
import { allSettled, allResolved } from "q";
import FieldForm from "./form/FieldForm";

const DEFAULT_INDEX = 0;

export default {
  components: {
    FieldForm
  },
  data() {
    return {
      field: null,
      sections: [],
      selectSections: [],
      selectRows: [],
      defRows: [],
      allSelected: false,
      indeterminate: false,
      sectionCheck: []
    };
  },
  props: {
    propField: {
      default: null
    },
    propRows: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    field: function() {
      if (this.field) {
        this.sections = this.field.sectionsSet.edges;
        this.sectionCheck = [];
      } else {
        this.sections = [];
        this.sectionCheck = [];
        this.selectRows = [];
        return;
      }
      for (let i = 0; i < this.sections.length; i++) {
        this.sectionCheck.push(true);
      }
      this.selectSections = [];
      this.selectRows = this.defRows;
      this.defRows = [];
      this.allSelected = false;
    },
    selectRows: function() {
      this.$emit("getField", this.field, this.selectRows);
    }
  },
  created() {
    if (this.propField) {
      this.defRows = this.propRows;
    }
  },
  methods: {
    setField: function(value) {
      this.field = value;
    },
    toggleAll: function(checked) {
      this.selectRows = [];

      if (checked) {
        this.selectSections = this.sections.slice();
        for (let i = 0; i < this.sections.length; i++) {
          this.sectionCheck[i] = false;
          for (let j = 0; j < this.sections[i].node.rowsSet.edges.length; j++) {
            this.selectRows.push({
              section: this.sections[i].node,
              row: this.sections[i].node.rowsSet.edges[j].node
            });
          }
        }
      } else {
        this.selectSections = [];
        for (let i = 0; i < this.sections.length; i++) {
          this.sectionCheck[i] = true;
        }
      }
    },
    toggleSection: function(index) {
      let rowLength = this.sections[index].node.rowsSet.edges.length;
      // //選択された区画の畝を削除
      for (let i = 0; i < this.selectRows.length; i++) {
        for (let j = 0; j < rowLength; j++) {
          let rowId = this.sections[index].node.rowsSet.edges[j].node.id;
          if (this.selectRows[i].row.id == rowId) {
            this.selectRows.splice(i, 1);
            i--;
            break;
          }
        }
      }

      //trueの場合畝をpush
      if (this.sectionCheck[index]) {
        for (let i = 0; i < rowLength; i++) {
          this.selectRows.push({
            section: this.sections[index].node,
            row: this.sections[index].node.rowsSet.edges[i].node
          });
        }
      }

      if (this.sectionCheck[index]) {
        this.sectionCheck[index] = false;
      } else {
        this.sectionCheck[index] = true;
      }
    }
  }
};
</script>
