<template>
  <b-form-group>
    <label for="name">作業時間</label>
    <b-form-select id="name" v-model="workTime" @change="updateValue" :disabled="true">
      <option v-for="item in calcuWorkTime()" :key="item.name" :value="item.value">{{item.name}}</option>
    </b-form-select>
  </b-form-group>
</template>


<script>
const INITIAL_TIME = 30;
export default {
  data() {
    return {
      workTime: 30
    };
  },
  props: {
    propWorkTime: {
      default: null
    }
  },
  watch: {
    propWorkTime: function() {
      this.workTime = this.propWorkTime;
      this.$emit("getWorkTime", this.workTime);
    }
  },
  created() {
    if (this.propWorkTime) {
      this.workTime = this.propWorkTime;
    }

    this.$emit("getWorkTime", this.workTime);
  },
  methods: {
    calcuWorkTime: function() {
      let times = [];
      let time = 30;
      times.push({ name: time + "分", value: time });
      for (let i = 1; i < 48; i++) {
        time += 30;
        if (time % 60 == 0) {
          times.push({ name: Math.floor(time / 60) + "時間", value: time });
        } else {
          times.push({
            name: Math.floor(time / 60) + "時間" + (time % 60) + "分",
            value: time
          });
        }
      }
      return times;
    },
    updateValue: function() {
      this.$emit("getWorkTime", this.workTime);
    }
  }
};
</script>
