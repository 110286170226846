<template>
  <div>
    <b-modal ref="dialog" ok-only>{{this.message}}</b-modal>
    <b-card>
      <h3>日報作成</h3>
      <b-row>
        <b-col>
          <date-form @getDate="setDate" />
        </b-col>
        <b-col>
          <operator-form @getOperator="setOperatorId" />
        </b-col>
      </b-row>
      <b-row>
        <operator-work-log-calendar :propDate="date" />
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="備考" label-for="remark">
            <b-form-textarea id="remark" v-model="remark" rows="5" max-rows="50" type="text" />
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="isCreate">
        <b-button
          type="submit"
          size="sm"
          variant="primary"
          v-on:click="createOperatorDailyReport()"
        >
          <i class="fa fa-dot-circle-o"></i>
          新規登録
        </b-button>
      </div>
      <div v-else>
        <b-button
          type="submit"
          size="sm"
          variant="primary"
          v-on:click="updateOperatorDailyReport()"
        >
          <i class="fa fa-dot-circle-o"></i>
          変更
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import OperatorForm from "../components/form/OperatorForm";
import DateForm from "../components/form/DateForm";
import OperatorWorkLogCalendar from "../components/OperatorWorkLogCalendar";

import {
  CREATE_OPERATOR_DAILY_REPORT,
  UPDATE_OPERATOR_DAILY_REPORT
} from "../../components/operator-daily-report-mutation";
import { OPERATOR_DAILY_REPORTS_QUERY } from "../../components/operator-daily-report-query";

import moment from "moment";

const DEFAULT_INDEX = 0;

export default {
  components: {
    OperatorForm,
    DateForm,
    OperatorWorkLogCalendar
  },
  data() {
    return {
      me: [],
      operatorDailyReports: [],
      operatorId: null,
      operatorDailyReportId: null,
      date: null,
      remark: "",
      isCreate: true,
      message: "保存しました"
    };
  },
  beforeMount() {
    this.operatorId = localStorage.getItem("graphcool-user-id");
    let today = new Date();
    this.date = moment(today).format("YYYY-MM-DD");
    this.getOperatorDailyReports();
  },
  watch: {
    operatorDailyReports: {
      handler: function(newVal, oldVal) {},
      deep: true
    },
    date: {
      handler: function(val) {
        this.date = val;
        if (this.operatorId != null) {
          this.getOperatorDailyReports();
        }
      }
    }
  },
  methods: {
    setOperatorId(operator) {
      this.operatorId = operator.id;
    },
    setDate(date) {
      this.date = date;
      this.getOperatorDailyReports();
      this.operatorDailyReportsRefetch();
    },
    operatorDailyReportsRefetch: function() {
      this.$apollo.queries.operatorDailyReports.refetch().then(result => {
        this.setRemark(result.data);
      });
    },
    setRemark(data) {
      if (!data.operatorDailyReports) {
        this.remark = data.operatorDailyReport.remark;
        this.operatorDailyReportId = data.operatorDailyReport.id;
        this.isCreate = false;
      } else if (data.operatorDailyReports.edges.length == 0) {
        this.isCreate = true;
        this.remark = "";
      } else {
        this.remark =
          data.operatorDailyReports.edges[DEFAULT_INDEX].node.remark;
        this.operatorDailyReportId =
          data.operatorDailyReports.edges[DEFAULT_INDEX].node.id;
        this.isCreate = false;
      }
    },
    createOperatorDailyReport() {
      this.$apollo
        .mutate({
          mutation: CREATE_OPERATOR_DAILY_REPORT,
          variables: {
            input: {
              operatorId: this.operatorId,
              date: this.date,
              remark: this.remark
            }
          }
        })
        .then(result => {
          this.setRemark(result.data.createOperatorDailyReport);
          this.message = "保存しました";
          this.$refs["dialog"].show();
        })
        .catch(error => {
          alert(error);
        });
    },
    updateOperatorDailyReport() {
      this.$apollo
        .mutate({
          mutation: UPDATE_OPERATOR_DAILY_REPORT,
          variables: {
            id: this.operatorDailyReportId,
            input: {
              operatorId: this.operatorId,
              date: this.date,
              remark: this.remark
            }
          }
        })
        .then(result => {
          this.setRemark(result.data.updateOperatorDailyReport);
          this.message = "更新しました";
          this.$refs["dialog"].show();
        })
        .catch(error => {
          alert(error);
        });
    },
    getOperatorDailyReports: function() {
      this.$apollo.addSmartQuery("operatorDailyReports", {
        query: OPERATOR_DAILY_REPORTS_QUERY,
        variables: {
          date: this.date,
          operatorId: this.operatorId
        },
        result({ data, load }) {
          this.setRemark(data);
        }
      });
    }
  }
};
</script>
