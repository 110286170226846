<template>
  <div>
    <!-- ダイアログ -->
    <b-modal ref="dialog" size="lg" @hidden="close" hide-footer title="変更">
      <operator-work-log-delete-modal
        :id="propOperatorWorkLog.id"
        @refetch="refetch"
        ref="operatorWorkLogDeleteModal"
      />
      <b-row>
        <b-col>
          <date-form :prop-date="this.propOperatorWorkLog.date" @getDate="setDate" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <operator-form @getOperator="setOperatorId" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>作業開始時間</label>
          <time-form
            :prop-time="this.propOperatorWorkLog.workTimeStart"
            @getTime="setWorkTimeStart"
          ></time-form>
        </b-col>
        <b-col>
          <label>作業終了時間</label>
          <time-form :prop-time="this.propOperatorWorkLog.workTimeEnd" @getTime="setWorkTimeEnd"></time-form>
        </b-col>
        <b-col>
          <label>作業時間</label>
          <h5>{{this.workTimeCalc}}</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <equipment-check-box
            :prop-operator-work-log="this.propOperatorWorkLog"
            @getOperatorWork="setOperatorWork"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <work-place-select-form
            :prop-field="this.propOperatorWorkLog.field"
            :prop-rows="this.rows"
            :prop-work-place="this.propOperatorWorkLog.workPlace"
            @getWorkPlace="setWorkPlace"
          />
          <b-form-input
            v-if="operatorWorkLog.workPlace==this.workPlace.FIELD"
            type="text"
            v-model="operatorWorkLog.fieldName"
            :disabled="true"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="備考" label-for="remark">
            <b-form-input id="remark" type="text" v-model="operatorWorkLog.remark"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button type="submit" size="sm" variant="primary" v-on:click="updateOperatorWorkLog">
        <i class="fa fa-dot-circle-o"></i> 更新
      </b-button>
      <b-button type="submit" size="sm" variant="danger" v-on:click="showDeleteDialog">
        <i class="fa fa-dot-circle-o"></i> 削除
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import OperatorForm from "./form/OperatorForm";
import WorkTimeStartForm from "./form/WorkTimeStartForm";
import WorkTimeForm from "./form/WorkTimeForm";
import TimeForm from "./form/TimeForm";
import DateForm from "./form/DateForm";
import EquipmentCheckBox from "./EquipmentCheckBox";
import WorkPlaceSelectForm from "./WorkPlaceSelectForm";
import OperatorWorkLogDeleteModal from "./OperatorWorkLogDeleteModal";
import { UPDATE_OPERATOR_WORK_LOG } from "../../components/operator-work-log-mutation";
import gql from "graphql-tag";
import moment from "moment";
import { workPlaceList } from "../../components/config";
export default {
  components: {
    OperatorForm,
    EquipmentCheckBox,
    WorkTimeStartForm,
    WorkTimeForm,
    TimeForm,
    DateForm,
    WorkPlaceSelectForm,
    OperatorWorkLogDeleteModal
  },
  data() {
    return {
      operatorWorkLog: {
        operatorId: null,
        operatorWorkId: null,
        fieldId: null,
        fieldName: null,
        workTimeStart: null,
        workTime: null,
        workPlace: null,
        remark: "",
        date: "",
        operatorworklogdetails: [],
        equipmentsoperatorworklogs: []
      },
      rows: [],
      workTimeEnd: null,
      workPlace: workPlaceList
    };
  },
  props: {
    propOperatorWorkLog: {
      type: Object,
      require: false,
      default: () => ({})
    }
  },
  computed: {
    workTimeCalc: function() {
      let time = this.operatorWorkLog.workTime;
      if (time < 60) {
        return time + "分";
      } else if (time % 60 == 0) {
        return Math.floor(time / 60) + "時間";
      } else {
        return Math.floor(time / 60) + "時間" + (time % 60) + "分";
      }
    }
  },
  watch: {
    propOperatorWorkLog: function() {
      this.init();
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (Object.keys(this.propOperatorWorkLog).length) {
        this.operatorWorkLog.remark = this.propOperatorWorkLog.remark;
        this.rows = [];
        let length = this.propOperatorWorkLog.operatorworklogdetailsSet.edges
          .length;
        for (let i = 0; i < length; i++) {
          this.rows.push({
            section: this.propOperatorWorkLog.operatorworklogdetailsSet.edges[i]
              .node.section,
            row: this.propOperatorWorkLog.operatorworklogdetailsSet.edges[i]
              .node.row
          });
        }
        this.operatorWorkLog.fieldName = this.propOperatorWorkLog.fieldName;
        this.operatorWorkLog.workPlace = this.propOperatorWorkLog.workPlace;
        this.operatorWorkLog.date = this.propOperatorWorkLog.date;
        this.operatorWorkLog.workTimeStart = moment(
          this.propOperatorWorkLog.workTimeStart
        ).format("h:mm");
        this.operatorWorkLog.workTime = this.propOperatorWorkLog.workTime;
        this.workTimeEnd = moment(this.propOperatorWorkLog.workTimeEnd).format(
          "h:mm"
        );
      }
    },
    setOperatorId(operator) {
      this.operatorWorkLog.operatorId = operator.id;
    },
    setOperatorWork(operatorWork) {
      this.operatorWorkLog.operatorWorkId = operatorWork.id;
      this.operatorWorkLog.equipmentsoperatorworklogs =
        operatorWork.equipmentIds;
    },
    setWorkTimeStart(workTimeStart) {
      this.operatorWorkLog.workTimeStart = workTimeStart;
      this.operatorWorkLog.workTime = this.timeCalc(
        this.operatorWorkLog.workTimeStart,
        this.workTimeEnd
      );
    },
    setWorkTimeEnd(workTimeEnd) {
      this.workTimeEnd = workTimeEnd;
      this.operatorWorkLog.workTime = this.timeCalc(
        this.operatorWorkLog.workTimeStart,
        this.workTimeEnd
      );
    },
    setField(fieldId, fieldName, workPlace) {
      this.operatorWorkLog.fieldId = fieldId;
      this.operatorWorkLog.fieldName = fieldName;
      this.operatorWorkLog.operatorworklogdetails = [];
      this.operatorWorkLog.workPlace = workPlace;
    },
    setDeleteField: function() {
      this.setField(
        this.propOperatorWorkLog.field.id,
        this.propOperatorWorkLog.fieldName,
        workPlaceList.FIELD
      );
      let _this = this;
      this.propOperatorWorkLog.operatorworklogdetailsSet.edges.forEach(function(
        val,
        index
      ) {
        _this.operatorWorkLog.operatorworklogdetails.push({
          sectionId: val.node.section.id,
          rowId: val.node.row.id,
          sectionName: val.node.sectionName,
          rowName: val.node.rowName
        });
      });
    },
    setWorkPlace(field, rows, workPlace) {
      if (!field && workPlace == workPlaceList.FIELD) {
        this.setDeleteField();
        return;
      }

      if (!field) {
        this.setField(field, field, workPlace);
        return;
      }
      this.setField(field.id, field.name, workPlace);
      let _this = this;
      rows.forEach(function(row, index) {
        _this.operatorWorkLog.operatorworklogdetails.push({
          sectionId: row.section.id,
          rowId: row.row.id,
          sectionName: row.section.name,
          rowName: row.row.name
        });
      });
    },
    setDate(date) {
      this.operatorWorkLog.date = date;
    },
    close: function() {
      this.init();
    },
    // --------------------------------
    // 変更ボタン
    // --------------------------------
    showEditDialog: function() {
      this.$refs["dialog"].show();
    },
    showDeleteDialog: function() {
      this.$refs.operatorWorkLogDeleteModal.showDeleteDialog();
    },
    refetch: function() {
      this.$refs["dialog"].hide();
      this.$emit("refetch");
    },
    timeCalc(start, end) {
      start = start.split(":");
      end = end.split(":");
      start = Number(start[0]) * 60 + Number(start[1]);
      end = Number(end[0]) * 60 + Number(end[1]);
      return end - start;
    },
    // --------------------------------
    // 更新
    // --------------------------------
    updateOperatorWorkLog: function() {
      this.$apollo
        .mutate({
          mutation: UPDATE_OPERATOR_WORK_LOG,
          variables: {
            id: this.propOperatorWorkLog.id,
            input: this.operatorWorkLog
          }
        })
        .then(data => {
          this.$refs["dialog"].hide();
        })
        .catch(error => {
          alert("更新エラー");
        });
    }
  }
};
</script>
